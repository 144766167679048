import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Typography, Timeline } from 'antd';
import moment from 'moment';
import creService from '../../../services/creService';
import DocsViewer from '../../../common/document-viewer'
import './windowSummaryRow.css'

const { Paragraph } = Typography;
const checkDate = (year) => {
  const newDate = new Date(year)
  newDate.setMonth(1)
  newDate.setDate(15)
  return newDate < new Date()
}
const ExpandedRow = (props) => {

  const history = useHistory();
  const [data, setData] = useState(props.data);
  const [email, setEmail] = useState(props.data.email)
  const [sending, setSending] = useState(false)
  const [currentfile, setCurrentfile] = useState(null);
  const [docsViewerVisibility, setDocsViewerVisibility] = useState(false);


  const getS3Url = async (key) => {

    // key = 'safety-mailings/2023/response-data/signatures/617-4474181-1-012624.pdf'

    const { data } = await creService.getDocumentByKey(key);

    setCurrentfile(data.document)
    setDocsViewerVisibility(true)
    // window.open(window.location.origin + `/api/documents/aws-s3-file-stream/${localStorage.getItem('cre-token')}:${data.document._id}`, '_blank');

  };

  useEffect(() => {
    if (props.data._id !== data._id) {
      setData(props.data);
      setEmail(props.data.email)

    }
  }, [props.data, data._id]);

  const markAsCompleted = async (state, action, responseData) => {
    try {
      const response =
        await creService.safetyMailingsMarkCompletedResponseAction({
          id: responseData._id,
          completed: state,
          action
        });
      if (response.data.status) {
        setData({
          ...data,
          responseData: response.data.responseData,
        });
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (_err) {
      toast.error(_err.message);
    }
  };

  const createWorkOrder = (data, action) => {
    history.push(
      `/work-order/create-by-safety-mailing-printed/${data.responseData._id}/${data.bin}?action=${action}`
    );
  };

  const sendEmail = async () => {
    try {
      setSending(true)
      const { data: reponseData } = await creService.sendSafetyMailingEmail({
        printedRow: data._id
      })

      if (reponseData.status) {
        setData({
          ...data,
          emailSendInfo: [...(data.emailSendInfo || []), reponseData.emailSendInfo],
        });
        toast.success('Email send to your email')
      } else {
        toast.error(reponseData.message)
      }

    } catch (err) {
      toast.error(err.message)
    }
    setSending(false)
  }

  const onChangeEmail = async (value) => {
    try {
      const result = await creService.setSafetyMailingEmail(value, data._id)
      if (result.data.status) {
        toast.success('New email set')
        setEmail(value)
      } else {
        toast.error(result.data.message);
      }

    } catch (err) {
      toast.error(err.message);
    }


  }
  const viewWorkOrder = (_id) => {
    history.push(`/work-orders/view/${_id}`);
  };



  return (
    <>
      <div className="row expand-row mt-0 justify-content-around m-0">
    

        {data.responseData && (
          <>
            <div className="card col-4 border-right rounded-0 p-0 m-0" >
              <div className="card-header">Survey Response</div>
              <div className="card-body">

                {
                  Object.keys(data.responseData.responses || {}).filter((key) => ['Yes', 'No'].includes(data.responseData.responses[key])).map(key =>
                    <div className="row d-flex align-items-center" key={key}>
                      <strong className="col-10">{key}</strong>
                      <input
                        type="checkbox"
                        disabled={true}
                        className="col-2"
                        checked={
                          data.responseData.responses[key] === 'Yes'
                        }
                      />
                    </div>
                  )
                }
                {data.responseData.audioSignature && <div className='mt-3'>
                  <div>Audio Sign</div>
                  <audio controls src={`http://localhost:8082/api/documents/get-by-key?key=${data.responseData.audioSignature}`}></audio>
                </div>}


              </div>
            </div>
            <div className="card col-8 rounded-0 p-0 m-0 " >
              <div className="card-header">
                <div className="row w-100 m-0">
                  <div className="col-6">Action Item </div>
                  <div className="col-2 p-0 small text-center">Completed</div>
                  <div className="col-4 p-0 small text-center">Order</div>
                </div>
              </div>
              <div className="card-body">
                {Object.keys(data.responseData?.actions || {}).length !== 0 ? (
                  Object.keys(data.responseData.actions).map(action => (
                    <div className="row mx-0 mb-2" key={action}>
                      <strong className="col-6">{action}</strong>

                      <div className="col-2 p-0 text-center">
                        <input
                          type="checkbox"
                          checked={data.responseData.actions[action].completed}
                          onChange={(event) =>
                            markAsCompleted(
                              event.target.checked,
                              action,
                              data.responseData
                            )
                          }
                          disabled={data.responseData.actions[action].workOrder}
                        />
                      </div>

                      <div className="col-4 p-0 text-center">
                        {data.responseData.actions[action].workOrder ? (
                          <button
                            onClick={() =>
                              viewWorkOrder(data.responseData.actions[action].workOrder)
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            view order
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              createWorkOrder(data, action)
                            }
                            className="align-items-center btn btn-primary btn-xs d-inline-flex px-1 py-0 ml-1"
                          >
                            <i className="fa fa-plus mr-1" /> Create
                          </button>
                        )}
                      </div>
                    </div>
                  ))
                )

                  : (
                    <div className="d-flex justify-content-center align-items-center">
                      <span>No actions available</span>
                    </div>
                  )}
              </div>
            </div>
            <div className="card col-12  rounded-0 p-0 m-0" >
          <div className="card-header">Address</div>
          <div className="card-body">
            <p className="mb-1">
              {data.building.display_address
                ? data.building.display_address
                : data.building.address}
            </p>
            <p className="mb-0">Apt# {data.aptNumber}</p>

            {data.responseData?.signature && (
              <button
                onClick={() =>
                  getS3Url(data.responseData.signature)
                }
                className="btn btn-link p-0 text-decoration-none"
              >
                View Digital Signature
              </button>
            )}

            {data.phoneReceiveInfo?.length ? <div className='card-body ' >
              <h5 className=' mt-3 mb-3 text-center '>Phone History</h5>
              <Timeline mode='left' className=' w-100'>{
                data.phoneReceiveInfo.reverse().map((phoneReceiveInfo, i) => {
                  const date = new Date()
                  date.setMinutes(date.getMinutes() - 20)
                  const status = (date > new Date(phoneReceiveInfo.date) && phoneReceiveInfo.status === 'in progress') ? 'hang up' : phoneReceiveInfo.status

                  return (
                    <Timeline.Item
                      label={moment(new Date(phoneReceiveInfo.date)).format('LLL')}
                      key={'phoneReceiveInfo' + i} className='ml-2' color={status === 'hang up' ? 'red' : (status === 'complited' ? 'green' : 'orange')}
                    >
                      <p>[{status === 'complited' ? 'Completed' : status}] [{phoneReceiveInfo.phoneNumber}]</p>

                    </Timeline.Item>
                  )
                }

                )
              }</Timeline></div> : ''
            }
            {data.browserSendInfo?.length ? <div className='card-body ' >
              <h5 className=' mt-3 mb-3 text-center '>SafeMailPro History</h5>
              <Timeline mode='left' className=' w-100'>{
                data.browserSendInfo.reverse().map((browserSendInfo, i) => {
                  const date = new Date()
                  date.setMinutes(date.getMinutes() - 20)
                  const status = (date > new Date(browserSendInfo.date) && browserSendInfo.status === 'in progress') ? 'hang up' : browserSendInfo.status

                  return (
                    <Timeline.Item
                      label={moment(new Date(browserSendInfo.date)).format('LLL')}
                      key={'phoneReceiveInfo' + i} className='ml-2' color={status === 'hang up' ? 'red' : (status === 'complited' ? 'green' : 'orange')}
                    >
                      <p>[{status === 'complited' ? 'Completed' : status}] [ip - {browserSendInfo.ip}]</p>

                    </Timeline.Item>
                  )
                }

                )
              }</Timeline></div> : ''
            }
          </div>


        </div>
          </>
        )}



        {
          !data.responseData?.isCompleted && (
            <div className="card col-12 d-flex justify-content-center align-items-center border-top">
              <div className='d-flex flex-column w-100 text-center' >
                <div className='d-flex justify-content-center text-left'>
                  {data.emailSendInfo?.map(emailSendInfo =>

                    <div key={emailSendInfo.emailTo} className='ml-2'>
                      <strong>Emailed At</strong> - {moment(new Date(emailSendInfo.emailAt)).format('MM/DD/YYYY')}<br />
                      <strong>Emailed To</strong> - {emailSendInfo.emailTo}<br />
                      <strong>Emailed By</strong> - {emailSendInfo.emailedBy?.fullName}
                    </div>
                  )
                  }

                </div>
                <div>
                  <Paragraph className='mt-2' editable={{ onChange: onChangeEmail }}>{email}</Paragraph>
                </div>
              </div>
              <strong> Response incompleted yet</strong>
              <div className='mt-1'>
                {(!data.emailSendInfo || (data.emailSendInfo.filter(em => em.emailTo === email).length < 2)) && checkDate(props.year) &&
                  <>
                    <button type="button" className="btn btn-sm btn-outline-primary mr-2" disabled={!data.email || sending} onClick={sendEmail}>
                      {data.email ? (sending ? 'Sendng ...' : `Send Email ${(data.emailSendInfo.filter(em => em.emailTo === email)).length ? 'Second Time' : ''}`) : 'No Email Information'}
                    </button>

                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary mr-2"
                      disabled={!data.email} onClick={() => window.open("/safety-mailings/email-temlate", "_blank")}
                    >
                      View Email Text
                    </button>
                  </>
                }
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary "
                  onClick={() => window.open(`/smf/${data._id}`, "_blank")}
                >
                  Offline Answer
                </button>

              </div>
            </div>
          )
        }
        <DocsViewer document={currentfile} isVisible={docsViewerVisibility} setIsVisible={setDocsViewerVisibility} useAWSURL={true} />
      </div >

    </>
  );
};

export default ExpandedRow;
